<template>
<!--  公寓详情-->
  <div class="apartmentDetails">
    <div>
      <my-carousel :bannerList="banners"></my-carousel>
    </div>
    <div class="content2 hidden-xs-only" >
      <div class="title">{{roomDeatil.name}}</div>
      <div class="price">HK/{{roomDeatil.rentMoney}}/{{$t('studentapartmentDetail.月')}}</div>
      <div class="details">
        <div class="card">
          <div class="title2">{{$t('studentapartmentDetail.简介')}}</div>
          <div class="xiangqin">
           {{roomDeatil.introduce}}
          </div>
          <div class="title2">{{$t('studentapartmentDetail.周边')}}</div>
          <div class="xiangqin">
            {{roomDeatil.periphery}}
          </div>
          <!-- <div class="title2">{{$t('studentapartmentDetail.设施')}}</div>
          <div class="xiangqin" style="color: #999">
            超市：百佳、惠康步行5分钟；商场：朗豪坊、圆方步行15分钟；医疗：伊利沙伯医院步行10分钟；健身：健
            身房私人健身中心步行10分钟，九龙公园体育馆步行15分钟；交通：佐敦地铁站A出口步行5分钟，柯士甸地
            铁站A出口及油麻地站C出口步行10分钟，西九龙站巴士总站步行10分钟
          </div>
          <div class="title2">{{$t('studentapartmentDetail.设施')}}</div>
          <div class="xiangqin" style="color: #999">
            超市：百佳、惠康步行5分钟；商场：朗豪坊、圆方步行15分钟；医疗：伊利沙伯医院步行10分钟；健身：健
            身房私人健身中心步行10分钟，九龙公园体育馆步行15分钟；交通：佐敦地铁站A出口步行5分钟，柯士甸地
            铁站A出口及油麻地站C出口步行10分钟，西九龙站巴士总站步行10分钟
          </div> -->
        </div>
        <div class="card2">
          <div class="xinxi">{{$t('studentapartmentDetail.公寓信息')}}</div>
          <div class="gongyu">
              <div>{{$t('studentapartmentDetail.公寓名称')}}<span>{{apartment.name}}</span></div>
              <div>{{$t('studentapartmentDetail.附近地铁')}}<span>{{apartment.subway}}</span></div>
              <div>{{$t('studentapartmentDetail.附近大学')}}<span>{{apartment.university}}</span></div>
              <div>{{$t('studentapartmentDetail.详细地址')}}<span>{{apartment.address}}</span></div>
              <div>{{$t('studentapartmentDetail.租期类型')}}<span>{{apartment.leaseTermType}}</span></div>
              <div>{{$t('studentapartmentDetail.房型')}}<span>{{apartment.roomType}}</span></div>
          </div>
        </div>
      </div>
      <div class="peizhi">
        <div class="card">
          <div>
            <div class="img">
              <img src="../../assets/image/456.png" alt=""/>
              <div>{{$t('studentapartmentDetail.公寓服务')}}</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in roomDeatil.apartmentService" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
          <div>
            <div class="img ">
              <img src="../../assets/image/chuang.png" alt=""/>
              <div>{{$t('studentapartmentDetail.房间设施')}}</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in roomDeatil.roomDevice" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
          <div>
            <div class="img">
              <img src="../../assets/image/yifu.png" alt=""/>
              <div>{{$t('studentapartmentDetail.公寓设施')}}</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in apartment.apartmentDevice" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
        </div>
        <div class="card2">
          <img :src="roomDeatil.floorPlan" alt="">
        </div>
      </div>
      <div class="zufang">
        <div class="title">{{$t('studentapartmentDetail.租房须知')}}</div>
        <div class="xuzhi" v-for="(item,index) in Rentrule" :key='index'>
          <div class="name">{{item.title}}</div>
          <div class="list2" style="word-wrap: break-word;word-break: normal;white-space: pre-line;">
            {{ item.content }}
          </div>
        </div>
      </div>
      <div style=" height: 54px;"></div>
    </div>
    <!-- 手机端 -->
    <div class="hidden-sm-and-up  apartDetail-phone" >
      <div>
        <div class="title">{{roomDeatil.name}}</div>
        <div class="price">HK/{{roomDeatil.rentMoney}}/{{$t('studentapartmentDetail.月')}}</div>
        <div class="details">
          <div class="card">
            <div class="title2">{{$t('studentapartmentDetail.简介')}}</div>
            <div class="xiangqin">
              {{roomDeatil.introduce}}
            </div>
            <div class="title2">{{$t('studentapartmentDetail.周边')}}</div>
            <div class="xiangqin">
             {{roomDeatil.periphery}}
            </div>
          </div>
          <div class="card2">
            <div class="xinxi">{{$t('studentapartmentDetail.公寓信息')}}</div>
            <div class="gongyu">
              <div>{{$t('studentapartmentDetail.公寓名称')}}<span>{{apartment.name}}</span></div>
              <div>{{$t('studentapartmentDetail.附近地铁')}}<span>{{apartment.subway}}</span></div>
              <div>{{$t('studentapartmentDetail.附近大学')}}<span>{{apartment.university}}</span></div>
              <div>{{$t('studentapartmentDetail.详细地址')}}<span>{{apartment.address}}</span></div>
              <div>{{$t('studentapartmentDetail.租期类型')}}<span>{{apartment.leaseTermType}}</span></div>
              <div>{{$t('studentapartmentDetail.房型')}}<span>{{apartment.roomType}}</span></div>
            </div>
          </div>
        </div>
        <div class="card-title">
            <div class="title2">{{$t('studentapartmentDetail.设施')}}</div>
        </div>  
      <div class="peizhi">
        <div class="card">
          <div>
            <div class="img">
              <img src="../../assets/image/456.png" alt=""/>
              <div>{{$t('studentapartmentDetail.公寓服务')}}</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in roomDeatil.apartmentService" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
          <div>
            <div class="img">
              <img src="../../assets/image/chuang.png" alt=""/>
              <div>{{$t('studentapartmentDetail.房间设施')}}</div>
            </div>
            <div class="list">
              <div  v-for="(item,index) in roomDeatil.roomDevice" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
          <div>
            <div class="img">
              <img src="../../assets/image/yifu.png" alt=""/>
              <div>{{$t('studentapartmentDetail.公寓设施')}}</div>
            </div>
            <div class="list">
              <div v-for="(item,index) in apartment.apartmentDevice" :key="index">
                <img src="../../assets/image/789.png" alt=""/>
                {{item}}
              </div>
            </div>
          </div>
        </div>
        <div class="card2">
          <img :src="roomDeatil.floorPlan" alt="">
        </div>
      </div>
      
      <div class="zufang">
        <div class="title">{{$t('studentapartmentDetail.租房须知')}}</div>
        <div class="xuzhi" v-for="(item,index) in Rentrule" :key='index'>
          <div class="name">{{item.title}}</div>
          <div class="list2" style="word-wrap: break-word;word-break: normal;white-space: pre-line;">
            {{ item.content }}
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCarousel from "@/components/carouseltwo";
import "element-ui/lib/theme-chalk/display.css";
import {roomDetils,getSearchSys} from '@/api/index.js'

export default {
  components: {
    MyCarousel,
  },
  data() {
    return {
      bannerList: [
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
      ],
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      roomDeatil:{},
      banners:[],
      Rentrule:[],
      apartment:{}
    };
  },
  created(){
    let roomId=this.$route.query.roomId
    // this.apartment =this.$route.query.apartment
    this._roomDetils(roomId)
    this._getSearchSys()
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
    window.addEventListener("resize", () => {
       that.$nextTick(() => {
        that.$forceUpdate()
        
        if (document.body.clientWidth <= 768) {
                 location.reload();
        }
      });
    });
  },
  methods: {
    _getSearchSys(){
      getSearchSys({
       category:8
      }).then(res=>{
        this.Rentrule=res.data
      })
    },
    _roomDetils(roomId ){
      roomDetils({
        roomId:roomId 
      }).then(res=>{
        this.roomDeatil=res.data.room
        this.apartment=res.data.apartment
        this.apartment.apartmentDevice=this.apartment.apartmentDevice.split(',')
        this.banners=this.roomDeatil.banners.split(',')
        this.roomDeatil.apartmentService= this.roomDeatil.apartmentService.split(',')
        this.roomDeatil.roomDevice=this.roomDeatil.roomDevice.split(',')
        console.log('bannerst',this.roomDeatil.banners)
      })
    }
  },
};
</script>

<style lang="scss">
.apartmentDetails {
  background-color: #f7f7f7;
  @media screen and (max-width: 768px) {
    .content2 {
      display: none;
    }
  }

  .content2 {
    width: 1176px;
    margin: 0 auto;
    padding-top: 70px;

    .zufang {
      text-align: left;
      margin-bottom: 16px;
      .xuzhi {
        display: flex;
        font-size: 16px;
        margin-bottom: 16px;

        .name {
          padding-top: 2px;
          width: 80px;
          margin-right: 16px;
          color: #999;
        }

        .list2 {
          color: #333;
          width: 670px;
          line-height: 30px;
        }
      }

      .title {
        font-size: 20px;
        color: #333;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    .peizhi {
      display: flex;
      margin-top: 40px;

      .card {
        text-align: left;
        width: 767px;
        margin-right: 45px;

        > div {
          display: flex;
          margin-bottom: 25px;

          .list {
            width: 600px;
            display: flex;
            flex-wrap: wrap;

            > div {
              // width: 25%;
              margin-bottom: 24px;
              margin-right: 20PX;

              > img {
                width: 30px;
                height: 30px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
          }

          .img {

            //   width: 115px;
            text-align: center;
            margin-right: 40px;

            > img {
              width: 60px;
              height: 60px;
            }

            > div {
              margin-top: 5px;
              font-size: 20px;
              color: #999;
            }
          }
        }
      }

      .card2 {
        width: 363px;
        height: 363px;
        border-radius: 12px;
        background-color: #fff;
        img{
          width: 363px;
          height: 363px;
        }
      }
    }

    .details {
      display: flex;
      margin-top: 40px;
      text-align: left;

      .card {
        width: 767px;
        margin-right: 45px;

        .title2 {
          font-size: 20px;
          color: #333;
          font-weight: 600;
          margin-bottom: 16px;
        }

        .xiangqin {
          font-size: 16px;
          color: #333;
          margin-bottom: 30px;
          line-height: 30px;
        }
      }

      .card2 {
        width: 363px;
        height: 472px;
        border-radius: 12px;
        margin-bottom: 20px;
        img{
          width: 363px;
        height: 472px;
        }
        .xinxi {
          text-align: center;
          font-size: 20px;
          color: #fff;
          width: 100%;
          height: 68px;
          line-height: 68px;
          background-color: #fe992b;
          border-radius: 12px 12px 0 0;
        }

        .gongyu {
          padding: 30px 22px 20px 22px;
          text-align: left;
          background-color: #fff;

          > div {
            font-size: 20px;
            color: #999;
            margin-bottom: 10px;

            > span {
              color: #333;
            }
          }
        }
      }
    }

    .title {
      color: #333;
      font-weight: 600;
      font-size: 32px;
    }

    .price {
      color: #f60606;
      font-size: 32px;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  // 移动端
  .apartDetail-phone{
   .title {
      color: #333;
      font-weight: 600;
      font-size:16PX;
      margin-top: 20PX;
    }

    .price {
      color: #f60606;
      font-size:16PX;
      font-weight: 600;
      margin-top:7PX;
    }
    .details {
      // display: flex;

      margin-top: 12PX;
      text-align: left;

      .card {
        width: 335PX;
        // margin-right: 45PX;
        margin: 0 auto;

        .title2 {
          font-size: 16PX;
          color: #333;
          font-weight: 600;
          margin-bottom: 10PX;
        }

        .xiangqin {
          font-size: 14PX;
          color: #333;
          margin-bottom: 20PX;
          line-height: 24PX;
        }
      }

      .card2 {
        width: 335PX;
        //height: 420PX;
        margin-top: 45PX;
        border-radius: 12PX;
        background-color: #fff;
        margin: 45PX auto 0 auto;
  img{
    width: 335PX;
        height: 420PX;
  }
        .xinxi {
          text-align: center;
          font-size:18PX;
          color: #fff;
          width: 100%;
          height: 68PX;
          line-height: 68PX;
          background-color: #fe992b;
          border-radius: 12PX 12PX 0 0;
        }

        .gongyu {
          padding-left: 22PX;
          padding-right: 22PX;
          padding-top: 10PX;
          padding-bottom: 30PX;
          text-align: left;

          > div {
            font-size: 16PX;
            color: #999;
            margin-bottom: 24PX;

            > span {
              color: #333;
            }
          }
        }
      }
    }
    .card-title{
      font-size: 16PX;
      font-weight: bold;
      margin: 20PX 0 16px 0;
      text-align: left;
      padding-left: calc(50vw - 167.5PX);
    }
    
    .peizhi {
      // display: flex;
      margin-top: 40PX;

      .card {
        text-align: left;
        width: 335PX;
        margin: 0 auto;

        > div {
          display: flex;
          margin-bottom: 25PX;

          .list {
            width: 335PX;
            display: flex;
            flex-wrap: wrap;

            > div {
              // width: 25%;
              margin-bottom: 24PX;
              font-size: 10PX;
              margin-right: 20PX;
              > img {
                width: 15PX;
                height:15PX;
                vertical-align: middle;
                margin-bottom: 2PX;
              }
            }
          }

          .img {

              width: 50PX;
            text-align: center;
            margin-right: 25PX;

            > img {
              width: 30PX;
              height: 30PX;
            }

            > div {
              margin-top: 5PX;
              font-size: 10PX;
              color: #999;
            }
          }
        }
      }

      .card2 {
        width: 335PX;
        height: 335PX;
        border-radius: 12px;
        background-color: #fff;
        margin: 0 auto;
        img{
          width: 335PX;
        height: 335PX;
        }
      }
    }
    .zufang {
      text-align: left;
      width: 335PX;
      margin: 0 auto;
      padding-bottom: 16PX;
      
      .zufangcontent{
        font-size: 14PX;
        margin-bottom: 16PX;
        line-height: 24PX;
      }
      .xuzhi {
        display: flex;
        font-size: 16PX;
        margin-bottom: 16PX;

        .name {
          padding-top: 2PX;
          width: 100PX;
          color: #999;
        }

        .list2 {
          color: #333;
          width:240PX;
          line-height: 30PX;
          font-size: 14PX;
        }
      }

      .title {
        font-size: 16PX;
        color: #333;
        font-weight: 600;
        margin-bottom: 16PX;
      }
    }
}
}

</style>
